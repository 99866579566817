<template>
  <div>
    <van-cell-group title="通用">
      <!-- <van-cell title="扫码" is-link size="large" icon="scan" to="/safe/rec/scan" /> -->
      <!-- <van-cell title="查看记录" is-link size="large" icon="search" to="/safe/rec" />
    <van-cell title="巡检报告" is-link size="large" icon="newspaper-o" to="/safe/rep" /> -->
      <!-- <van-cell v-for="(i, index) in list" :key="index" :title="i.Safe_Class_Name" is-link size="large"
        icon="newspaper-o"
        :to="{ path: '/safe/type', query: { name: i.Safe_Class_Name, Safe_Class_Id: i.ID, date: i.Frequency_Txt } }" /> -->


      <van-cell v-for="(i, index) in list" :key="index" :title="i.Safe_Class_Name" is-link size="large"
        icon="newspaper-o"
        :to="{ path: '/safe/type' + `${i.Safe_Class_Name == '安全巡检' ? '' : '2'}`, query: { name: i.Safe_Class_Name, Safe_Class_Id: i.ID, date: i.Frequency_Txt } }" />

      <!-- <van-cell v-else :title="i.Safe_Class_Name" is-link size="large" icon="newspaper-o"
        :to="{ path: '/safe/type2', query: { name: i.Safe_Class_Name, Safe_Class_Id: i.ID, date: i.Frequency_Txt } }" /> -->

      <!-- <van-cell title="消防巡检" is-link size="large" icon="newspaper-o" to="/safe/type" />
      <van-cell title="安全巡检" is-link size="large" icon="newspaper-o" to="/safe/type" /> -->
      <van-cell title="异常处理" is-link size="large" icon="newspaper-o" to="/safe/abnormal" />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      list: []
    }
  },
  created() {
    this.getList()
  },
  methods: {

    getList() {
      let self = this
      this.whale.remote.getCollection({
        url: '/api/Mobile/SAFE/SafeClassConfig/GetPageList',
        completed(m) {
          console.log(m)
          self.list = m.ITEMS
        }
      })
    }
  },
}
</script>

<style scoped></style>